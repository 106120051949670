<template>
  <div class="section-container signin register">
    <v-container>
      <v-row class="signin">
        <v-col cols="12" lg="12" class="right text-center">
          <div class="inner" style="position: relative">
            <v-overlay absolute :value="dataLoading">
              <v-progress-circular indeterminate />
            </v-overlay>
            <div class="text-left d-flex align-center mb-2">
              <v-icon class="black--text" @click="routeBack()"
                >fa fa-arrow-left</v-icon
              >
              <span class="text-h4 black--text font-weight-bold ml-5"
                >Register</span
              >
            </div>
            <validation-observer
              ref="registerObserver"
              v-slot="{ handleSubmit }"
            >
              <v-form
                @submit.prevent="handleSubmit(register)"
                ref="registerForm"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="First name"
                      rules="required"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        class="form-control"
                        v-model="user.firstName"
                        :error-messages="errors"
                        placeholder="First name"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Last name"
                      rules="required"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        v-model="user.lastName"
                        class="form-control"
                        :error-messages="errors"
                        placeholder="Last name"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                      mode="lazy"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        v-model="user.email"
                        class="form-control"
                        :error-messages="errors"
                        placeholder="Email"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Password"
                      rules="required|password"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        v-model="user.password"
                        class="form-control"
                        :error-messages="errors"
                        placeholder="Password"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Confirm Password"
                      :rules="{ required: true, confirmed: user.password }"
                    >
                      <v-text-field
                        v-model="user.confirmPassword"
                        class="form-control"
                        :error-messages="errors"
                        placeholder="Confirm Password"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <validation-provider
                  v-slot="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <v-text-field
                    v-model="user.address"
                    class="form-control"
                    :error-messages="errors"
                    placeholder="Address"
                    required
                    outlined
                    dark
                    background-color="#f0f1f6"
                    color="#000"
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Phone"
                  :rules="{
                    required: true,
                    regex: /^(\d{3}-)\d{3}-\d{4}$/,
                  }"
                >
                  <v-text-field
                    class="form-control"
                    :error-messages="errors"
                    placeholder="Phone"
                    required
                    outlined
                    dark
                    background-color="#f0f1f6"
                    color="#000"
                    maxlength="12"
                    :value="acceptNumber(user.phone)"
                    @keypress="onlyNumbers"
                    @input="user.phone = $event.replaceAll('-', '')"
                    hint="The Phone format is XXX-XXX-XXXX"
                    persistent-hint
                  ></v-text-field>
                </validation-provider>
                <ValidationProvider
                  name="Cards"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="user.cardTypeId"
                    :items="cards"
                    required
                    outlined
                    dark
                    placeholder="Cards"
                    class="form-control"
                    background-color="#f0f1f6"
                    color="#000"
                    item-text="cardTypeName"
                    item-value="id"
                    multiple
                    :error-messages="errors"
                    chips
                    deletable-chips
                  >
                    <template v-slot:selection="{ attrs, item, selected }">
                      <v-chip
                        :ripple="false"
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click:close="remove(item)"
                      >
                        <strong>{{ item.cardTypeName }}</strong>
                      </v-chip>
                    </template>
                  </v-select>
                </ValidationProvider>
                <validation-provider
                  v-slot="{ errors }"
                  name="Role"
                  rules="required"
                >
                  <v-select
                    :items="roles"
                    v-model="user.roleID"
                    :error-messages="errors"
                    class="form-control"
                    placeholder="Role"
                    item-value="id"
                    item-text="roleName"
                    required
                    outlined
                    dark
                    background-color="#f0f1f6"
                    color="#000"
                    append-icon="fa-caret-down"
                  ></v-select>
                </validation-provider>
                <div class="text-center">
                  <v-btn
                    class="signin-btn"
                    type="submit"
                    primary
                    dark
                    rounded
                    :disabled="loading"
                    :loading="loading"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AuthService from "@/services/AuthService";
import HomeService from "@/services/HomeService";
import { mapState } from "vuex";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        address: "",
        phone: "",
        roleID: 1,
      },
      show1: false,
      show2: false,
      loading: false,
      roles: [],
      dataLoading: false,
    };
  },
  async created() {
    this.dataLoading = true;
    HomeService.getRoles()
      .then((res) => {
        this.dataLoading = false;
        this.roles = res.data.data;
      })
      .catch((error) => {
        this.dataLoading = false;
        this.$store.dispatch(
          "notifications/setNotification",
          {
            text: "Error While Fetching Roles",
            type: "error",
            status: error.response.status,
          },
          { root: true }
        );
      });
    if (this.cards.length === 0) {
      await this.$store.dispatch("receipt/FETCH_CARDS");
    }
  },
  computed: {
    ...mapState({
      cards: (state) => state.receipt.cards.filter((card) => card.active),
    }),
  },
  methods: {
    async register() {
      this.loading = true;
      await AuthService.register(this.user)
        .then((res) => {
          this.$store.dispatch("SET_USER_EDDITED", true);
          this.loading = false;
          this.$store.dispatch("notifications/setNotification", {
            type: "success",
            text: `${
              this.roles.find((role) => role.id === res.data.data.roleID)
                .roleName
            } Created Successfully`,
          });
          this.$router.push("/admin");
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch("notifications/setNotification", {
            type: "error",
            text: error.response.data.errorMessage,
            status: error.response.status,
          });
        });
    },
    resetLogin() {
      this.$refs.registerObserver.reset();
      this.$refs.registerForm.reset();
    },
    acceptNumber(value) {
      if (value !== null && value !== "" && value !== 0) {
        const x = String(value)
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2]
          ? `${x[1]}`
          : `${x[1]}-${x[2]}${x[3] ? `${"-" + x[3]}` : ""}`;
      }
    },
    onlyNumbers(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    remove(item) {
      this.user.cardTypeId.splice(
        this.user.cardTypeId.findIndex((cardId) => {
          return cardId === item.id;
        }),
        1
      );
      this.user.cardTypeId = [...this.user.cardTypeId];
    },
    async routeBack() {
      await this.$store.dispatch("SET_USER_EDDITED", true);
      this.$router.push("/admin");
    },
  },
};
</script>
